import React from 'react';

export default settings => {
    return props => {
        const devEmail = props.value;
        if (devEmail === 'other') {
            return (
                <a href="mailto:goulet@3cisd.com" target="_blank" rel="noopener noreferrer">
                    Other
                </a>
            );
        }
        if (devEmail === 'impact') {
            return (
                <a href="mailto:rich@3cisd.com" target="_blank" rel="noopener noreferrer">
                    Impact
                </a>
            );
        }

        return (
            <a href="mailto:huegel@3cisd.com" target="_blank" rel="noopener noreferrer">
                Quest/DELP
            </a>
        );
    };
};
