import React from 'react';
import TableTop from 'cccisd-tabletop';
import legacy from './legacy.graphql';
import Tooltip from 'cccisd-tooltip';
import Question4 from 'cccisd-icons/question4';
import Pencil7 from 'cccisd-icons/pencil7';
import Modal from 'cccisd-modal';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

const LegacyApps = () => {
    const hyperlinkRender = props => {
        const hyperlink = props.value;
        if (!hyperlink) {
            return '';
        }
        return (
            <a href={hyperlink} target="_blank" rel="noopener noreferrer">
                {hyperlink.replace(/\/$/, '').replace('https://', '')}
            </a>
        );
    };
    const renders = {
        hyperlink: hyperlinkRender,
        appInfoRender: props => {
            return (
                <Modal
                    trigger={
                        <div className="text-center">
                            <button type="button" className="btn btn-default">
                                <Pencil7 />
                            </button>
                        </div>
                    }
                    title={props.row['group.label']}
                    size="large"
                >
                    <div>
                        <DeploymentPlayer
                            deploymentHandle="app_info"
                            disableLayout
                            pawnId={props.row['descendantRoles.metricspawn.pawn.pawnId']}
                            pawnHash={props.row['descendantRoles.metricspawn.pawn.pawnHash']}
                        />
                    </div>
                </Modal>
            );
        },
        nameTooltip: props => {
            const descTooltip = props.row['fields.git_description'];
            const name = props.row['group.label'];
            const tooltip = (
                <Tooltip title={descTooltip} placement="top">
                    <Question4 />
                </Tooltip>
            );
            return (
                <div>
                    {tooltip} {name}
                </div>
            );
        },
    };

    return (
        <TableTop
            handle="apps"
            views={[
                {
                    handle: 'legacy',
                    title: 'Legacy',
                    table: {
                        query: legacy,
                        renders,
                        rowKey: 'group.groupId',
                        columns: [
                            {
                                name: 'group.label',
                                label: 'Name',
                                render: 'nameTooltip',
                                sort: true,
                                filter: true,
                            },
                            {
                                name: 'fields.production_url',
                                label: 'Production',
                                render: 'hyperlink',
                                filter: true,
                            },
                            {
                                name: 'fields.wordpress_url',
                                label: 'Wordpress',
                                render: 'hyperlink',
                                filter: true,
                            },
                            {
                                name: 'app_info',
                                label: 'App Info',
                                tooltip: <div>Click to open a survey that displays application details.</div>,
                                width: '80px',
                                render: 'appInfoRender',
                            },
                        ],
                        perPage: 20,
                    },
                },
            ]}
        />
    );
};

export default LegacyApps;
