import React, { useEffect, useState, useContext } from 'react';
import { SiteDropdownContext } from '../../components/SiteDropdown/index.js';
import axios from 'cccisd-axios';
import style from './style.css';

const Packages = () => {
    const SiteDropdown = useContext(SiteDropdownContext);
    const [dependencies, setDependencies] = useState([]);
    useEffect(() => {
        // package tag list
        const getDependencies = async () => {
            if (!SiteDropdown.selectedSite.git_handle || !SiteDropdown.selectedTag) {
                return;
            }
            const response = await axios.get(
                '/api/monitoring/dependencies?handle=' +
                    SiteDropdown.selectedSite.git_handle +
                    '&tag=' +
                    SiteDropdown.selectedTag
            );
            setDependencies(response.data.data);
        };
        getDependencies();
    }, [SiteDropdown.selectedSite.git_handle, SiteDropdown.selectedTag]);

    const RenderPackages = () => {
        const composerList = dependencies.map((val, index) => {
            const namesplit = val.name.split('/');
            const url = val.url;
            let frontendLink = false;
            if (val.requires && Object.entries(val.requires).length) {
                const depVer = val.requires.version;
                const depName = val.requires.name.replace('cccisd-laravel-', '').replace('cccisd-', '');
                const prefix = val.requires.name.startsWith('cccisd-laravel-') ? 'cccisd-laravel-' : 'cccisd-';
                const depUrl = val.requires.url;
                frontendLink = (
                    <span className={style.frontEnd}>
                        &rarr; <span className={style.cccisd}>{prefix}</span>
                        {depName}:{' '}
                        <a href={depUrl} target="_BLANK" rel="noopener noreferrer">
                            {depVer}
                        </a>
                    </span>
                );
            }
            return (
                <tr key={index}>
                    <td className={style.label}>
                        <span className={style.cccisd}>{`${namesplit[0]}/`}</span>
                        {namesplit[1]}
                    </td>
                    <td className={style.value}>
                        <a href={url} target="_BLANK" rel="noopener noreferrer">
                            {val.version}
                        </a>
                        {frontendLink}
                    </td>
                </tr>
            );
        });
        return (
            <>
                <div className={style.tableWrap}>
                    <h2 style={{ textAlign: 'center' }}>Packages</h2>
                    <table className="table table-condensed table-bordered" style={{ margin: 'auto', width: 'auto' }}>
                        <thead />
                        <tbody>{composerList}</tbody>
                    </table>
                </div>
            </>
        );
    };
    return (
        <div>
            {SiteDropdown.renderDropdown(true)}
            <RenderPackages />
        </div>
    );
};

export default Packages;
