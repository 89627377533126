import React from 'react';
import Tabs from 'cccisd-tabs';
import Packages from './bySite';
import PackagesList from './byPackage';

const tabList = [
    { name: 'byPackage', title: 'By Package', content: <PackagesList /> },
    { name: 'bySite', title: 'By Site', content: <Packages /> },
];

export default () => <Tabs tabList={tabList} />;
