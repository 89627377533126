import React from 'react';

export default settings => {
    return props => {
        const hyperlink = props.value;
        return (
            <a href={hyperlink} target="_blank" rel="noopener noreferrer">
                {hyperlink}
            </a>
        );
    };
};
