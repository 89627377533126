import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

const Callout = props => {
    const { id, notes, rating } = props;

    return (
        <div className={style.wrapper}>
            <div className={style.id}>{id}</div>
            <div className={style.rating}>{rating}</div>
            {notes && <div className={style.notes}>{notes}</div>}
        </div>
    );
};

Callout.propTypes = {
    id: PropTypes.string,
    notes: PropTypes.string,
    rating: PropTypes.object,
};

export default Callout;
