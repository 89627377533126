import React from 'react';
import TableTop from 'cccisd-tabletop';
import decommissioned from './decommissioned.graphql';

const DecommissionedApps = () => {
    const hyperlinkRender = (props) => {
        const hyperlink = props.value;
        if (!hyperlink) {
            return '';
        }
        return (
            <a href={hyperlink} target="_blank" rel="noopener noreferrer">
                {hyperlink.replace(/\/$/, '').replace('https://', '')}
            </a>
        );
    };
    const renders = {
        hyperlink: hyperlinkRender,
    };

    return (
        <TableTop
            handle="apps"
            views={[
                {
                    handle: 'decommissioned',
                    title: 'Decommissioned',
                    table: {
                        query: decommissioned,
                        renders,
                        rowKey: 'group.groupId',
                        columns: [
                            {
                                name: 'group.label',
                                label: 'Name',
                                sort: true,
                                filter: true,
                            },
                            {
                                name: 'fields.production_url',
                                label: 'Decommissioned URL',
                                render: 'hyperlink',
                                filter: true,
                            },
                            {
                                name: 'fields.comments',
                                label: 'Comments',
                                filter: true,
                            },
                        ],
                        perPage: 20,
                    },
                },
            ]}
        />
    );
};

export default DecommissionedApps;
