import React, { useEffect, useState, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { SiteDropdownContext } from '../../components/SiteDropdown/index.js';

const ReleaseNotes = () => {
    const SiteDropdown = useContext(SiteDropdownContext);
    const [markdown, setMarkdown] = useState('');
    useEffect(() => {
        if (SiteDropdown.selectedSite.git_handle) {
            fetch('/api/monitoring/release-notes?handle=' + SiteDropdown.selectedSite.git_handle)
                .then(response => {
                    if (response.status !== 200) {
                        let error = new Error(response.status);
                        error.status = response.status;
                        throw error;
                    } else {
                        return response.json();
                    }
                })
                .then(data => {
                    let lines = data.data['release-notes'].split('\n');

                    let formattedLines = lines.map(line => {
                        if (line.startsWith('## ')) {
                            let version = line.substring(3);
                            return `## [${version}](/Packages?version=${version})`;
                        }
                        return line;
                    });

                    let formattedNotes = formattedLines.join('\n');
                    setMarkdown(formattedNotes);
                })
                .catch(error => {
                    if (error.status === 404) {
                        setMarkdown(
                            '## No release notes are currently available for this application.'
                        );
                    } else {
                        setMarkdown();
                    }
                });
        }
    }, [SiteDropdown.selectedSite.git_handle]);
    return (
        <div>
            {SiteDropdown.renderDropdown()}
            <ReactMarkdown>{markdown}</ReactMarkdown>
        </div>
    );
};

export default ReleaseNotes;
