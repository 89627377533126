import React from 'react';
import Tooltip from 'cccisd-tooltip';
import PropTypes from 'prop-types';

const ActionButton = ({ className, icon, onClick, tooltipText }) => {
    return (
        <Tooltip title={tooltipText}>
            <button onClick={onClick} className={`btn btn-${className} btn-sm`} type="button">
                {icon}
            </button>
        </Tooltip>
    );
};

ActionButton.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.object,
    onClick: PropTypes.func,
    tooltipText: PropTypes.string,
};

export default ActionButton;
