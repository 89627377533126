import React, { useState, useEffect } from 'react';
import { client } from 'cccisd-apollo';
import dropdown from './dropdown.graphql';
import axios from 'cccisd-axios';

export const SiteDropdownContext = React.createContext({
    allSites: '',
    renderDropdown: props => {},
    selectedSite: { git_handle: '', label: '', production_url: '', groupId: -1 },
});

const SiteDropdownWrapper = props => {
    const [allSites, setAllSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState({
        label: '',
        git_handle: '',
        production_url: '',
        groupId: -1,
    });
    const [tagList, setTagList] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            const data = await client.query({ query: dropdown });
            setAllSites(data.data.groups.organizationList);
            const monitoringSite = data.data.groups.organizationList.find(element => {
                return element.fields.git_handle === 'monitoring';
            });
            setSelectedSite({
                label: monitoringSite.group.label,
                git_handle: monitoringSite.fields.git_handle,
                production_url: monitoringSite.fields.production_url,
                groupId: 126,
            });
        };

        fetchData().catch(console.error);
    }, []);
    const renderDropdown = isPackagesDropdown => {
        if (isPackagesDropdown) {
            useEffect(() => {
                const fetchTagData = async () => {
                    if (selectedSite.git_handle !== '') {
                        const response = await axios.get(
                            '/api/monitoring/tags?handle=' + selectedSite.git_handle
                        );
                        if (response.data.success === true) {
                            setTagList(response.data.data);
                            setSelectedTag(response.data.data[0]);
                        }
                    }
                };
                fetchTagData();
            }, [selectedSite.git_handle]);
        }
        return (
            <div>
                <form className="form-inline">
                    <div className="form-group">
                        <label
                            htmlFor="site"
                            style={{
                                marginRight: '5px',
                                marginBottom: '0px',
                                marginTop: '5px',
                            }}
                        >
                            Site
                        </label>
                        <select
                            value={selectedSite.git_handle}
                            className="form-control"
                            id="site"
                            onChange={e => {
                                let desiredSite = allSites.find(
                                    site => site.fields.git_handle === e.target.value
                                );
                                setSelectedTag('');
                                setSelectedSite({
                                    git_handle: desiredSite.fields.git_handle,
                                    label: desiredSite.group.label,
                                    production_url: desiredSite.fields.production_url,
                                    groupId: desiredSite.group.groupId,
                                });
                            }}
                        >
                            {allSites.map(o => (
                                <option key={o.fields.git_handle} value={o.fields.git_handle}>
                                    {o.group.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    {isPackagesDropdown && (
                        <div className="form-group" style={{ marginLeft: '10px' }}>
                            <label
                                htmlFor="tag"
                                style={{
                                    marginRight: '5px',
                                    marginBottom: '0px',
                                    marginTop: '5px',
                                }}
                            >
                                Tag
                            </label>
                            <select
                                value={selectedTag}
                                className="form-control"
                                id="tag"
                                onChange={e => {
                                    let desiredTag = tagList.find(tag => tag === e.target.value);
                                    setSelectedTag(desiredTag);
                                }}
                            >
                                {tagList.map(o => (
                                    <option key={o} value={o}>
                                        {o}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </form>
                <hr />
            </div>
        );
    };

    return (
        <SiteDropdownContext.Provider
            value={{ renderDropdown, allSites, selectedSite, tagList, selectedTag }}
        >
            {props.children}
        </SiteDropdownContext.Provider>
    );
};

export default SiteDropdownWrapper;
