import React from 'react';

import { getYear, getMonth } from 'date-fns';

import IconBrokenHeart from 'cccisd-icons/heart-broken';
import IconHourglass from 'cccisd-icons/hour-glass';

export const renderAlert = (alertText, icon) => {
    const renderIcon = () => {
        switch (icon) {
            case 'hourglass':
                return <IconHourglass spaceRight />;

            case 'brokenHeart':
                return <IconBrokenHeart spaceRight />;

            default:
                return <IconBrokenHeart spaceRight />;
        }
    };

    return (
        <div className="alert alert-info" style={{ textAlign: 'center', marginTop: '1em' }}>
            <h5>
                {renderIcon()}
                {alertText}
            </h5>
        </div>
    );
};

export const thisYear = getYear(new Date());
export const thisMonth = getMonth(new Date()) + 1;

export const getMonthOfDate = date => getMonth(date) + 1;

export const janToJune = [1, 2, 3, 4, 5, 6];
export const julyToDec = [7, 8, 9, 10, 11, 12];

export const semesterTotal = semesterHours =>
    semesterHours.map(tableClient => +tableClient.devTags.supportMinutes).reduce((a, b) => a + b);

const currentYear = thisYear.toString();
const nextYear = (thisYear + 1).toString();
const oneYearAgo = (thisYear - 1).toString();
const twoYearsAgo = (thisYear - 2).toString();
const threeYearsAgo = (thisYear - 3).toString();
const fourYearsAgo = (thisYear - 3).toString();

export const yearSettings = {
    calendar: {
        currentYear,
        oneYearAgo,
        twoYearsAgo,
        threeYearsAgo,
    },
    midYear: {
        currentYear: {
            columnHeader: currentYear + '-' + nextYear + ' Hours',
            /*
            If the component renders between January-June, we need to start our count
            at last year's July 1st:
                the fall semester is last year's July-December
                the spring semester is this year, up to the current date.

            If the component renders between July-December, we need to start our count 
            at this year's July 1st:
                the fall semester is this year's July-December
                the spring semester would be next year's January-June, but we will not
                    have data for the future.     
            */
            fallSemesterYear: thisMonth <= 6 ? oneYearAgo : currentYear,
            springSemesterYear: thisMonth <= 6 ? currentYear : nextYear,
        },
        oneYearAgo: {
            columnHeader: oneYearAgo + '-' + currentYear + ' Hours',
            /*
            If the component renders between January-June, a year ago would mean:
                the fall semester is twoYearsAgo's July-December
                the spring semester is oneYearAgo

            If the component renders between July-December, a year ago would mean:
                the fall semester's oneYearAgo's July-December
                the spring semester is this year     
            */
            fallSemesterYear: thisMonth <= 6 ? twoYearsAgo : oneYearAgo,
            springSemesterYear: thisMonth <= 6 ? oneYearAgo : currentYear,
        },
        twoYearsAgo: {
            columnHeader: twoYearsAgo + '-' + oneYearAgo + ' Hours',
            /*
            If the component renders between January-June, two years ago would mean:
                the fall semester is threeYearsAgo's July-December
                the spring semester is twoYearsAgo

            If the component renders between July-December, two years ago would mean:
                the fall semester's twoYearsAgo's July-December
                the spring semester is oneYearAgo     
            */
            fallSemesterYear: thisMonth <= 6 ? threeYearsAgo : twoYearsAgo,
            springSemesterYear: thisMonth <= 6 ? twoYearsAgo : oneYearAgo,
        },
        threeYearsAgo: {
            columnHeader: threeYearsAgo + '-' + twoYearsAgo + ' Hours',
            /*
            If the component renders between January-June, three years ago would mean:
                the fall semester is fourYearsAgo's July-December
                the spring semester is threeYearsAgo

            If the component renders between July-December, two years ago would mean:
                the fall semester's threeYearsAgo's July-December
                the spring semester is twoYearsAgo     
            */
            fallSemesterYear: thisMonth <= 6 ? fourYearsAgo : threeYearsAgo,
            springSemesterYear: thisMonth <= 6 ? threeYearsAgo : twoYearsAgo,
        },
    },
};
