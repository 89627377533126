import React, { useContext, useState, useEffect } from 'react';
import { SiteDropdownContext } from '../../components/SiteDropdown/index.js';
import Style from './style.css';
import axios from 'cccisd-axios';

const Usages = props => {
    const SiteDropdown = useContext(SiteDropdownContext);
    let UsageData = '/api/monitoring/usageData?handle=' + SiteDropdown.selectedSite.git_handle;
    const [usageData, setUsageData] = useState({});

    useEffect(() => {
        const getUsageData = async () => {
            const response = await axios.get(UsageData);
            setUsageData(response.data);
        };
        getUsageData().catch(() => setUsageData(false));
    }, [SiteDropdown.selectedSite.git_handle]);

    let UsageList = [];
    if (usageData.success === true) {
        for (const [key, value] of Object.entries(usageData.data.users)) {
            UsageList.push(
                <p className={Style.tabBreakdown} key={key}>
                    {key}: {value}
                </p>
            );
        }
    }

    const openLogs = siteUrl => {
        const url = `${siteUrl}/appdefs/logs#userslog`;
        window.open(url, '_blank');
    };

    return (
        <div>
            {SiteDropdown.renderDropdown()}
            {usageData.success === true ? (
                <>
                    <h2 style={{ display: 'inline-block' }}>{SiteDropdown.selectedSite.label}</h2>
                    <div>
                        <p className={Style.tabTotal}>Total Users: {usageData.data.totalUsers}</p>
                        {UsageList}
                    </div>
                    <div style={{ display: 'inline-block', marginLeft: '20px' }}>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => openLogs(SiteDropdown.selectedSite.production_url)}
                            style={{ margin: '2px' }}
                        >
                            Site Logs
                        </button>
                    </div>
                </>
            ) : (
                <p>This site needs a platform upgrade to be able to retrieve usage stats.</p>
            )}
        </div>
    );
};

export default Usages;
