import React from 'react';
import Tabs from 'cccisd-tabs';
import CurrentApps from './current';
import LegacyApps from './legacy';
import DecommissionedApps from './decommissioned';

const tabList = [
    { name: 'current', title: 'Current', content: <CurrentApps /> },
    { name: 'legacy', title: 'Legacy', content: <LegacyApps /> },
    { name: 'decommissioned', title: 'Decommissioned', content: <DecommissionedApps /> },
];

export default () => <Tabs tabList={tabList} />;
