import React, { useState } from 'react';
import Modal from 'cccisd-modal';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import PropTypes from 'prop-types';
import Table from 'cccisd-graphql-table';
import notification from 'cccisd-notification';
import copy from 'copy-to-clipboard';
import Loader from 'cccisd-loader';
import Confirm from 'cccisd-confirm';
import axios from 'cccisd-axios';
import Tooltip from 'cccisd-tooltip';

import style from './style.css';
import tableQuery from './passwordTable.graphql';
import ActionButton from './ActionButton';
import showLoader from './showLoader.js';

import IconAdd from 'cccisd-icons/plus-circle2';
import IconBin from 'cccisd-icons/bin';
import IconClipboard from 'cccisd-icons/copy';
import IconEye from 'cccisd-icons/eye';
import IconPencil from 'cccisd-icons/pencil';

const Boilerplate = window.cccisd.boilerplate;

const PasswordTable = props => {
    const [isLoading, setIsLoading] = useState(false);

    const { canModify, deploymentHandle, id } = props;

    const columns = [
        { name: 'devTags.passwordSiteName', label: 'Site', sort: true, filter: true },
        {
            name: 'devTags.passwordUrl',
            label: 'URL',
            sort: true,
            filter: true,
            render: ({ value }) => (
                <a href={`${value}`} target="_blank" rel="noopener noreferrer">
                    {value}
                </a>
            ),
        },
        {
            name: 'devTags.passwordLogin',
            label: 'Login',
            render: 'login',
            sort: true,
            filter: true,
            sortSettings: {
                field: ['devTags.passwordLogin'],
            },
        },
        { name: 'password', label: 'Password', render: 'password', width: '10%', hideInCsv: true },
        { name: 'devTags.password', label: 'Password', hideInTable: true },
        { name: 'devTags.passwordDescription', label: 'Description/Notes', width: '40%', sort: true, filter: true },
    ];

    const canModifyColumns = [
        {
            name: 'actions',
            label: 'Actions',
            render: 'actions',
            hideInCsv: true,
            width: '10%',
        },
        ...columns,
    ];

    const copyText = (text, type) => {
        copy(text);

        notification({
            message: `${type} copied successfully!`,
            type: 'success',
            duration: 8000,
        });
    };

    const forceUpdate = async () => {
        setIsLoading(true);
        setIsLoading(false);
    };

    return (
        <Loader loading={isLoading} removeChildren>
            {!isLoading && (
                <div className={style.container}>
                    {canModify && (
                        <div className={style.add}>
                            <Modal
                                trigger={
                                    <button className="btn btn-primary" type="button">
                                        <IconAdd spaceRight />
                                        Add Password
                                    </button>
                                }
                                title="Add Password"
                                headerStyle={{
                                    backgroundColor: '#007298',
                                    color: '#fff',
                                }}
                                formAutoFocus
                                afterClose={() => forceUpdate()}
                                render={({ closeModal }) => (
                                    <div className={style.fixContainer}>
                                        <DeploymentPlayer
                                            deploymentHandle={deploymentHandle}
                                            onComplete={() => closeModal()}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    )}

                    <Table
                        query={tableQuery}
                        rowKey="assignmentProgressId"
                        csvFilename={`Passwords_${id}.csv`}
                        graphqlVariables={{ deploymentHandle }}
                        columns={canModify ? canModifyColumns : columns}
                        renders={{
                            actions: ({ row }) => {
                                function deletePassword() {
                                    Confirm({
                                        message: 'Are you sure?',
                                        description:
                                            'Password, along with all related survey progress, will be permanently deleted. This cannot be undone. Proceed?',
                                        confirmLabel: 'Yes',
                                        abortLabel: 'No',
                                    }).then(() => {
                                        showLoader(async () => {
                                            await axios.post(
                                                Boilerplate.route('api.assignment.assignmentProgress.delete'),
                                                {
                                                    assignmentProgressIds: [row.assignmentProgressId],
                                                    targetedPermissionGroupId: row['assignment.groupId'],
                                                }
                                            );
                                            await forceUpdate();
                                        }).fail(() => {
                                            // do nothing
                                        });
                                    });
                                }

                                return (
                                    <div className={style.actionButtons}>
                                        <Modal
                                            trigger={
                                                <ActionButton
                                                    icon={<IconPencil />}
                                                    className="success"
                                                    tooltipText="Edit Password"
                                                />
                                            }
                                            title="Edit Password"
                                            headerStyle={{
                                                backgroundColor: '#007298',
                                                color: '#fff',
                                            }}
                                            afterClose={() => forceUpdate()}
                                            render={({ closeModal }) => (
                                                <div className={style.fixContainer}>
                                                    <DeploymentPlayer
                                                        deploymentId={row['deployment.deploymentId']}
                                                        pawnId={row['pawn.pawn.pawnId']}
                                                        pawnHash={row['pawn.pawn.pawnHash']}
                                                        onComplete={() => closeModal()}
                                                    />
                                                </div>
                                            )}
                                        />
                                        <ActionButton
                                            className="danger"
                                            icon={<IconBin />}
                                            onClick={() => deletePassword()}
                                            tooltipText="Delete Password"
                                        />
                                    </div>
                                );
                            },
                            login: ({ row }) => {
                                return (
                                    <div className={style.login}>
                                        {row['devTags.passwordLogin']}
                                        <ActionButton
                                            className="warning"
                                            icon={<IconClipboard />}
                                            onClick={() => copyText(row['devTags.passwordLogin'], 'Login')}
                                            tooltipText="Copy to clipboard"
                                        />
                                    </div>
                                );
                            },
                            password: ({ row }) => {
                                return (
                                    <div className={style.actionButtons}>
                                        <ActionButton
                                            className="warning"
                                            icon={<IconClipboard />}
                                            onClick={() => copyText(row['devTags.password'], 'Password')}
                                            tooltipText="Copy to clipboard"
                                        />
                                        <Tooltip title={`${row['devTags.password']}`}>
                                            <div className={style.viewPassword}>
                                                <IconEye />
                                            </div>
                                        </Tooltip>
                                    </div>
                                );
                            },
                        }}
                    />
                </div>
            )}
        </Loader>
    );
};

PasswordTable.propTypes = {
    canModify: PropTypes.bool,
    deploymentHandle: PropTypes.string,
    id: PropTypes.string,
};

export default PasswordTable;
