import React from 'react';
import ReactDOM from 'react-dom';
import Loader from 'cccisd-loader';

// Add div to the body where we will render the confirm modal
const wrapper = document.createElement('div');
document.body.appendChild(wrapper);

export default async func => {
    ReactDOM.render(<Loader loading />, wrapper);

    try {
        await func();
    } catch (error) {
        ReactDOM.unmountComponentAtNode(wrapper);
        throw error;
    }

    ReactDOM.unmountComponentAtNode(wrapper);
};
