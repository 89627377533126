import React, { useEffect, useState } from 'react';
import Tabs from 'cccisd-tabs';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import PasswordTable from './PasswordTable';
import deploymentQuery from './deployments.graphql';
import { renderAlert } from '../dashboardHelpers';

const Fortress = window.cccisd.fortress;

const Passwords = () => {
    const [passwordDeployments, setPasswordDeployments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function getDeploymentList() {
        const response = await client.query({
            query: deploymentQuery,
            fetchPolicy: 'network-only',
            variables: {
                passwordDeployments: ['billing', 'developers', 'devOps', 'media', 'old', 'pm', 'support', 'wp'],
            },
        });
        setPasswordDeployments(response.data.flows.deploymentList);
        setIsLoading(false);
    }

    useEffect(() => {
        getDeploymentList();
    }, []);

    const hasTabAccess = name => {
        const currentDeployment = passwordDeployments.filter(pd => pd.deploymentHandle === name);

        if (Array.isArray(currentDeployment) && currentDeployment.length === 1) {
            return Fortress.hasAccess('quest.view_identifiable_data', currentDeployment[0].assignment.groupId);
        }
        return false;
    };

    const canModify = name => {
        const currentDeployment = passwordDeployments.filter(pd => pd.deploymentHandle === name);

        if (Array.isArray(currentDeployment) && currentDeployment.length === 1) {
            return Fortress.hasAccess('quest.modify_data', currentDeployment[0].assignment.groupId);
        }
        return false;
    };

    const passwordTabs = [
        { name: 'developers', title: 'Developers' },
        { name: 'devOps', title: 'Dev Ops' },
        { name: 'media', title: 'Media' },
        { name: 'old', title: 'Old' },
        { name: 'pm', title: 'PM' },
        { name: 'support', title: 'Support' },
        { name: 'wp', title: 'WordPress' },
        { name: 'billing', title: 'Billing' },
    ];

    for (const tab of passwordTabs) {
        tab.access = hasTabAccess(tab.name);
        tab.content = <PasswordTable canModify={canModify(tab.name)} deploymentHandle={tab.name} id={tab.name} />;
    }

    const tabList = passwordTabs.filter(tab => tab.access);

    if (tabList.length < 1) {
        return renderAlert('No password permissions found for your account.');
    }

    return (
        <Loader loading={isLoading} removeChildren>
            {!isLoading && <Tabs tabList={tabList} />}
        </Loader>
    );
};

export default Passwords;
