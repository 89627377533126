import React from 'react';
import TableTop from 'cccisd-tabletop';
import currentDeployments from './current_deployments.graphql';
import currentProduction from './current_production.graphql';
import currentWordpress from './current_wordpress.graphql';
import Tooltip from 'cccisd-tooltip';
import Question4 from 'cccisd-icons/question4';
import CheckboxChecked2 from 'cccisd-icons/checkbox-checked2';
import CancelCircle2 from 'cccisd-icons/cancel-circle2';
import ExpiredIcon from 'cccisd-icons/alarm-cancel';
import format from 'date-fns/format';
import differenceInDays from 'date-fns/difference_in_days';
import Pencil7 from 'cccisd-icons/pencil7';
import Modal from 'cccisd-modal';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

const CurrentApps = () => {
    const hyperlinkRender = props => {
        const hyperlink = props.value;
        if (!hyperlink) {
            return '';
        }
        return (
            <a href={hyperlink} target="_blank" rel="noopener noreferrer">
                {hyperlink.replace(/\/$/, '').replace('https://', '')}
            </a>
        );
    };
    const renders = {
        hyperlink: hyperlinkRender,
        versionReleaseProd: props => {
            const versionProd = props.row['group.settings.environments.production.current_version'];
            const releaseProd = props.row['fields.latest_release_production'];
            if (versionProd === 'NA') {
                return <div>{hyperlinkRender(props)}</div>;
            }
            if (releaseProd === null) {
                return <div>{hyperlinkRender(props)}</div>;
            }
            return (
                <div>
                    {hyperlinkRender(props)}
                    <div>
                        <a href="/ReleaseNotes">{versionProd}</a> | {format(new Date(releaseProd), 'MM/DD/YYYY')}
                    </div>
                </div>
            );
        },
        versionReleaseQA: props => {
            const versionQA = props.row['group.settings.environments.qa.current_version'];
            const releaseQA = props.row['fields.latest_release_qa'];
            const statusQA = props.row['group.settings.environments.qa.container.status'];
            const statusTimeQA = props.row['group.settings.environments.qa.container.timestamp'];

            if (versionQA === 'NA') {
                return <div>{hyperlinkRender(props)}</div>;
            }
            if (releaseQA === null) {
                return <div>{hyperlinkRender(props)}</div>;
            }

            let statusLine = '';
            if (statusQA === 'expired') {
                statusLine = (
                    <div className="text-danger">
                        <ExpiredIcon /> {statusQA} | {format(new Date(statusTimeQA * 1000), 'MM/DD/YYYY')}
                    </div>
                );
            }

            return (
                <div>
                    {hyperlinkRender(props)}
                    <div>
                        <a href="/ReleaseNotes">{versionQA}</a> | {format(new Date(releaseQA), 'MM/DD/YYYY')}
                    </div>
                    {statusLine}
                </div>
            );
        },
        versionReleaseStaging: props => {
            const versionStaging = props.row['group.settings.environments.staging.current_version'];
            const releaseStaging = props.row['fields.latest_release_staging'];
            const statusStaging = props.row['group.settings.environments.staging.container.status'];
            const statusTimeStaging = props.row['group.settings.environments.staging.container.timestamp'];

            if (versionStaging === 'NA') {
                return <div>{hyperlinkRender(props)}</div>;
            }
            if (releaseStaging === null) {
                return <div>{hyperlinkRender(props)}</div>;
            }

            let statusLine = '';
            if (statusStaging === 'expired') {
                statusLine = (
                    <div className="text-danger">
                        <ExpiredIcon /> {statusStaging} | {format(new Date(statusTimeStaging * 1000), 'MM/DD/YYYY')}
                    </div>
                );
            }

            return (
                <div>
                    {hyperlinkRender(props)}
                    <div>
                        <a href="/ReleaseNotes">{versionStaging}</a> | {format(new Date(releaseStaging), 'MM/DD/YYYY')}
                    </div>
                    {statusLine}
                </div>
            );
        },
        nameTooltip: props => {
            const descTooltip = props.row['fields.git_description'];
            const name = props.row['group.label'];
            const tooltip = (
                <Tooltip title={descTooltip} placement="top">
                    <Question4 />
                </Tooltip>
            );
            return (
                <div>
                    {tooltip} {name}
                </div>
            );
        },
        platformVersionRender: props => {
            const platformVersion = props.row['fields.platform_version_production'];
            if (platformVersion === null) {
                return <div />;
            }
            if (platformVersion === 'NA') {
                return <div />;
            }
            return <div>{platformVersion}</div>;
        },
        latestReleaseRender: props => {
            const latestRelease = props.row['fields.latest_release_production'];
            if (latestRelease === null) {
                return <div />;
            }
            return <div>{format(new Date(latestRelease), 'MM/DD/YYYY')}</div>;
        },
        appInfoRender: props => {
            return (
                <Modal
                    trigger={
                        <div className="text-center">
                            <button type="button" className="btn btn-default">
                                <Pencil7 />
                            </button>
                        </div>
                    }
                    title={props.row['group.label']}
                    size="90vw"
                >
                    <div>
                        <DeploymentPlayer
                            deploymentHandle="app_info"
                            disableLayout
                            pawnId={props.row['descendantRoles.metricspawn.pawn.pawnId']}
                            pawnHash={props.row['descendantRoles.metricspawn.pawn.pawnHash']}
                        />
                    </div>
                </Modal>
            );
        },
        recentOutageRender: props => {
            const recentOutage = props.row['fields.recent_outage'];
            const status = props.row['fields.status'];
            let outageDays = differenceInDays(new Date(), new Date(recentOutage));
            if (outageDays <= 30 || status === 'red') {
                return (
                    <div>
                        {format(new Date(recentOutage * 1000), 'MM/DD/YYYY')}
                        <div>{format(new Date(recentOutage * 1000), 'hh:mm:ss A')}</div>
                    </div>
                );
            }
            return <div />;
        },
        statusRender: props => {
            const allStatus = props.row['fields.status'];
            if (allStatus === 'green') {
                return (
                    <div className="text-success text-center">
                        <CheckboxChecked2 />
                    </div>
                );
            }
            if (allStatus === 'red') {
                return (
                    <div className="text-danger text-center">
                        <CancelCircle2 />
                    </div>
                );
            }
            return <div />;
        },
    };
    return (
        <TableTop
            handle="apps"
            views={[
                {
                    handle: 'Production',
                    title: 'Production',
                    table: {
                        query: currentProduction,
                        rowKey: 'group.groupId',
                        renders,
                        columns: [
                            {
                                name: 'group.label',
                                label: 'Name',
                                tooltip: <div>This is the internal name for the production site.</div>,
                                render: 'nameTooltip',
                                sort: true,
                                filter: true,
                            },
                            {
                                name: 'fields.status',
                                label: 'Status',
                                tooltip: (
                                    <div>
                                        This is the current status of the production site and WordPress front-end (if
                                        applicable). Green check means both are currently functioning normally. Red
                                        circle means one or both are currently out of service.
                                    </div>
                                ),
                                width: '110px',
                                render: 'statusRender',
                                sort: true,
                                filter: true,
                                filterSettings: {
                                    type: 'selectbox',
                                    placeholder: '',
                                    options: [
                                        { label: 'Green', value: 'green' },
                                        { label: 'Red', value: 'red' },
                                        { label: 'None', value: 'unknown' },
                                    ],
                                },
                            },
                            {
                                name: 'fields.recent_outage',
                                label: 'Recent Outage',
                                tooltip: (
                                    <div>
                                        This is the most recent date/time in the past 30 days where an outage occurred.
                                        See the Status tab for more detailed information.
                                    </div>
                                ),
                                render: 'recentOutageRender',
                                sort: true,
                            },
                            {
                                name: 'main_url',
                                label: 'Main URL',
                                render: 'hyperlink',
                                tooltip: (
                                    <div>
                                        This is the url of the WordPress home page (if applicable) or it is the home
                                        page of the production website.
                                    </div>
                                ),
                                sort: true,
                                filter: true,
                            },
                            {
                                name: 'fields.platform_version_production',
                                label: 'Platform Version',
                                tooltip: <div>This is the platform version of the production site.</div>,
                                render: 'platformVersionRender',
                                sort: true,
                                filter: true,
                            },
                            {
                                name: 'fields.latest_release_production',
                                label: 'Last Release',
                                tooltip: <div>This is the date of the last time the production site was deployed.</div>,
                                render: 'latestReleaseRender',
                                sort: true,
                                filter: true,
                                filterSettings: { type: 'date' },
                            },
                            {
                                name: 'app_info',
                                label: 'App Info',
                                tooltip: <div>Click to open a survey that displays application details.</div>,
                                width: '100px',
                                render: 'appInfoRender',
                            },
                        ],
                        perPage: 20,
                    },
                },
                {
                    handle: 'Deployments',
                    title: 'Deployments',
                    table: {
                        query: currentDeployments,
                        renders,
                        rowKey: 'group.groupId',
                        columns: [
                            {
                                name: 'group.label',
                                label: 'Name',
                                render: 'nameTooltip',
                                sort: true,
                                filter: true,
                            },
                            {
                                name: 'fields.git_handle',
                                label: '3C Site Handle',
                                sort: true,
                                filter: true,
                            },
                            {
                                name: 'fields.wordpress_url',
                                label: 'Wordpress',
                                render: 'hyperlink',
                                sort: true,
                                filter: true,
                            },
                            {
                                name: 'fields.techLead',
                                label: 'Tech Lead',
                                sort: true,
                                filter: true,
                            },
                            {
                                name: 'fields.production_url',
                                label: 'Laravel Production',
                                render: 'versionReleaseProd',
                                sort: true,
                                filter: true,
                            },
                            {
                                name: 'fields.qa_url',
                                label: 'Laravel QA',
                                render: 'versionReleaseQA',
                                sort: true,
                                filter: true,
                            },
                            {
                                name: 'fields.staging_url',
                                label: 'Laravel Staging',
                                render: 'versionReleaseStaging',
                                sort: true,
                                filter: true,
                            },
                        ],
                        perPage: 20,
                    },
                },
                {
                    handle: 'Wordpress',
                    title: 'Wordpress',
                    table: {
                        query: currentWordpress,
                        rowKey: 'group.groupId',
                        renders,
                        columns: [
                            {
                                name: 'group.label',
                                label: 'Name',
                                render: 'nameTooltip',
                                sort: true,
                                filter: true,
                            },
                            {
                                name: 'fields.wordpress_url',
                                label: 'Production Wordpress',
                                render: 'hyperlink',
                                sort: true,
                                filter: true,
                            },
                            {
                                name: 'fields.staging_wordpress',
                                label: 'Staging Wordpress',
                                render: 'hyperlink',
                                sort: true,
                                filter: true,
                            },
                        ],
                        perPage: 20,
                    },
                },
            ]}
        />
    );
};

export default CurrentApps;
