import React, { useEffect, useState } from 'react';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import { client } from 'cccisd-apollo';

import clientsQuery from '../clients.graphql';
import { renderAlert } from '../../dashboardHelpers';

const ClientSupportPlayer = () => {
    const [saasOrgs, setSaasOrgs] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                await client
                    .query({
                        query: clientsQuery,
                        fetchPolicy: 'network-only',
                    })
                    .then(response => {
                        const orgNames = response.data.groups.organizationList;
                        setSaasOrgs(orgNames);
                    });
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    return saasOrgs.length > 0 ? (
        <DeploymentPlayer
            deploymentHandle="support_tracking"
            flowProps={{
                variables: {
                    orgList:
                        saasOrgs &&
                        saasOrgs.map(org => ({
                            id: org.group.groupId,
                            name: `${org.group.label} (${org.mainUrl})`,
                        })),
                },
            }}
        />
    ) : (
        <div>{renderAlert('Please add at least 1 SaaS Client in Manage', 'brokenHeart')}</div>
    );
};

export default ClientSupportPlayer;
